<template>
    <div>
        <div class="order-page-bg">
            <button-back/>
        </div>
        <div class="order-card">

            <div class="order-card-decorate">
                <b-g-decorate type="home"></b-g-decorate>
            </div>
            <div class="order-card-title">
                <span class="order-card-title-icon"/>
                <span class="order-card-title-label">订单查询</span>
            </div>

            <div class="infinite-list-wrapper" style="overflow:auto">
                <div v-infinite-scroll="initData"
                     infinite-scroll-disabled="disabled">
                    <div v-for="(item,i) in data" :key="i" class="order-card-item" :style="styleItem">
                        <div class="order-card-item-header">
                            <div class="order-card-item-title">
                                {{item['itemName']}}
                            </div>

                            <!--  INIT-初始化;-->
                            <!--  PROCESS-处理中;-->
                            <!--  SUCCESS-成功;-->
                            <!--  FAILED-失败;-->
                            <div class="order-card-item-header-tag"
                                 :class="{'success':item['status'] == 'SUCCESS','error':item['status'] == 'FAILED'}">
                                <div>
                                    {{item['statusName']}}
                                </div>
                            </div>
                        </div>
                        <!--                    内容-->
                        <div class="order-card-item-body">
                            <div class="order-card-item-body-content">
                                <span>学校：{{item['schoolName']}}</span><br/>
                                <span>年级：{{item['gradeName']}}  班级:{{item['className']}}</span><br/>
                                <span>学生姓名：{{item['studentName']}}</span><br/>
                                <span>订单编号：{{item['orderNo']}}</span><br/>
                                <span>交易时间：{{item['tradeDate']}}:{{item['tradeTime']}} </span><br/>
                                <span v-if="item['finishTime']">完成时间：{{item['finishTime']}} </span><br/>
                            </div>
                            <div class="order-card-item-body-right">
                                <div>
                                    <span class="order-card-item-body-money">¥{{item['tradeAmt']}}</span>
                                    <span v-if="item['payModeType'] == 'ALIPAY'">
                                    <svg-icon icon="icon_alipay_active" width="15" height="15"/>
                                </span>
                                    <span v-else-if="item['payModeType'] == 'WECHAT'">
                                    <svg-icon icon="icon_weixin_active" width="15" height="15"/>
                                </span>
                                </div>

                                <!--                                <el-row v-if="!['PROCESS','INIT'].indexOf(item['status'])>-1" type="flex"-->
                                <!--                                        justify="end">-->
                                <!--                                    <span class="btn-cancel">取消订单</span>-->
                                <!--                                    <span class="btn-pay">重新支付</span>-->
                                <!--                                </el-row>-->
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="loading">加载中...</p>
                <p v-if="noMore">没有更多了</p>
            </div>

        </div>
    </div>
</template>

<script>
    import ButtonBack from "@/components/ButtonBack";
    import BGDecorate from "@/components/BGDecorate";

    export default {
        name: "OrderPage",
        data() {
            return {
                styleItem: {
                    backgroundImage: `url(${require('@images/bg_card_item.svg')})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    paddingBottom: '10px',
                },
                curPage: 0,
                pageSize: 20,
                data: [],
                loading: false,
                noMore: false,
            }
        },
        watch: {
            curPage() {
                this.initData()
            }
        },
        computed: {
            disabled() {
                return this.loading || this.noMore
            }
        },
        components: {ButtonBack, BGDecorate},
        created() {
            this.initData()
        },
        methods: {
            initData() {
                this.loading = true
                this.$api.queryOrderList({
                    phone: this.user.phone,
                    currentPage: this.curPage,
                    pageSize: this.pageSize
                }).then(res => {
                    if (this.curPage == 0) {
                        this.data.length = 0
                    }
                    if (res && res.length >= this.pageSize) {
                        this.curPage++
                        this.data = this.data.concat(res)
                    } else {
                        this.data = this.data.concat(res)
                        this.noMore = true
                    }
                }).catch(e => {
                    this.$notify.error(e.message())
                }).finally(() => {
                    this.loading = false
                })
            },
            //重新支付
            requestRePay() {

            },
            requestCancelPay() {

            }
        }
    }
</script>

<style scoped lang="less">

    .order-page-bg {
        position: absolute;
        width: 100%;
        height: 100vh;
        background: linear-gradient(155.73deg, #E66720 23.84%, rgba(244, 45, 45, 0) 66.2%);
    }

    .order-card {
        background: #FFFFFF;
        box-shadow: 0px -4px 100px #E5E5E5;
        border-radius: 50px 50px 0px 0px;
        position: absolute;
        width: 100%;
        height: calc(100vh - 68px);
        top: 68px;

        &-decorate {
            position: absolute;
            top: 0px;
            bottom: 0px;
            width: 100%;
            z-index: 0;
        }

        &-title {
            position: relative;
            text-align: center;
            height: 60px;

            &-label {
                position: absolute;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18.9755px;
                color: #000000;
                left: 30px;
                top: 30px;
            }

            &-icon {
                position: absolute;
                width: 90px;
                height: 12px;
                background: #FFB957;
                border-radius: 5px;
                left: 25px;
                top: 45px;
            }
        }
    }

    .order-card-item {
        margin: 20px;
        filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));

        &-bg {
            position: absolute;
            width: 100%;
            z-index: -1;
        }

        &-header {
            position: relative;
            background: linear-gradient(90.2deg, #EB905D -2.46%, #FFE6A5 136.83%);
            border-radius: 10px 10px 0px 0px;
            height: 45px;
            text-align: left;
            font-size: 14px;

            &-tag {
                position: absolute;
                right: -10px;
                top: 10px;
                background: linear-gradient(94.66deg, #EC915D -17.58%, #FFB957 116.96%);
                border-radius: 12px;
                color: white;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 6.48837px;
                line-height: 8px;
                padding: 8px;
            }

            &-tag.success {
                background: linear-gradient(94.66deg, #8BEC5D -17.58%, #01A959 116.96%);
            }

            &-tag.error {
                background: linear-gradient(94.66deg, #ff0000 -17.58%, #ff789f 116.96%);
            }
        }

        &-title {
            padding: 15px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12.9755px;
            line-height: 15px;
            color: #FFFFFF;
        }

        //内容--
        &-body {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            &-content {
                text-align: left;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
            }

            &-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .btn-cancel {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10.4884px;
                    line-height: 12px;
                    color: #D2C3C3;
                    margin-right: 11px;
                }

                .btn-pay {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10.4884px;
                    line-height: 12px;
                    color: #F7B60F;
                }
            }

            &-money {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                margin: 10px;
                line-height: 18px;
                color: #F7B60F;
            }
        }
    }
</style>
